import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/SEO"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "../components/reviews.module.scss"
import Img from "gatsby-image"

function selectKind(kind) {
  if (kind === "solo") {
    return "Solo Traveler"
  } else if (kind === "couple") {
    return "Couple"
  } else if (kind === "family") {
    return "Family"
  } else {
    return null
  }
}

const title = ""
const description =
  ""

export default ({ data }) => (
  <div>
    <SEO pageTitle={title} pageDescription={description} />
    <Header headerText="Home Page" />
    <Container fluid className={styles.reviewPage}>
      <h1 className="title">{data.markdownRemark.frontmatter.title}</h1>
      <div className="description" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      <Row noGutters>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <Col lg={12} md={12} sm={12} key={node.id}>
            <div className={(index % 2 === 1) ? styles.block : styles.blockAlt} id={node.id}>
              <div className={styles.item}>
                <Row noGutters>
                  <Col md={3}>
                    <div className={styles.image}>
                      <Img
                        fixed={node.frontmatter.photo.childImageSharp.fixed}
                        alt={node.frontmatter.photo.alt}
                      />
                    </div>
                    <div className={styles.author}>
                      <p>{selectKind(node.frontmatter.kind)}</p>
                      <h3>{node.frontmatter.author}</h3>
                      <p className={styles.date}>{node.frontmatter.date}</p>
                    </div>
                  </Col>
                  <Col  md={9} className={styles.quoteContainer}>
                    <div
                      className={styles.quote}
                      dangerouslySetInnerHTML={{ __html: node.html }}
                    ></div>                    
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
    <Footer />
  </div>
)

export const query = graphql`
  query allReviewsQuery {
    markdownRemark(frontmatter: { path: { eq: "/reviews-content" } }) {
      html
      frontmatter {
        title                    
      }
    }
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "reviews" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          html
          fields {
            slug
            contentType
          }
          frontmatter {
            quote
            author
            date(formatString: "MMMM YYYY")
            kind
            path
            photo {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
